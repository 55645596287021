<template>
  <div>
    <header-title :title="isAdd?'新增预警':'编辑预警'"></header-title>
    <rs-form :model="formData" :show-message="false" label-width="156px" size="small"  ref="formData" class="form-100 commom-form">
        <rs-row>  
            <rs-col :span="12">
                <rs-form-item label="项目名称">
                  <rs-select :popper-append-to-body="false" v-model="formData.mallSlect" popper-class="dialog-popper" value-key="mallCode" placeholder="请选择">
                      <rs-option v-for="item in mallList" :key="item.mallCode" :label="item.mallName" :value="item">
                      </rs-option>
                  </rs-select>
                </rs-form-item>
            </rs-col>
        </rs-row>
        <rs-row>  
            <rs-col :span="12">
                <rs-form-item label="预警类型">
                  <rs-select :popper-append-to-body="false" v-model="formData.configType" popper-class="dialog-popper" value-key="id" placeholder="请选择">
                      <rs-option v-for="item in warnList" :key="item" :label="item" :value="item">
                      </rs-option>
                  </rs-select>
                </rs-form-item>
            </rs-col>
        </rs-row>
        <rs-row>  
            <rs-col :span="6">
                <rs-form-item label="预警天数">
                  <rs-input maxlength="8" v-model.number="formData.configValue" popper-class="dialog-popper" value-key="id"></rs-input>
                </rs-form-item>
            </rs-col>
            <rs-col :span="6" class="configType">
              <rs-form-item>
                  <rs-select :popper-append-to-body="false" v-model="formData.configValue1" popper-class="dialog-popper" placeholder="请选择">
                    <rs-option v-for="(item,index) in warnDateTypeList" :key="index" :label="item.dictName" :value="item.dictValue"></rs-option>
                  </rs-select>
              </rs-form-item>
            </rs-col>
        </rs-row>
    </rs-form>
    <div class="edit-form button-box" style="text-align: right">
        <rs-button size="small"  @click="back">取消</rs-button>
        <rs-button size="small" @click="save" type="primary" :loading="saveLoading">保存</rs-button>
    </div>
  </div>
</template>
<script>
import Utils from '@/utils/utils'
import Api from '@/api/reportServer'
import Api1 from '@/api/api'
import headerTitle from '@/components/header'
export default {
  components:{headerTitle},
  data(){
    return {
      isAdd:true,
      modifyId:'',
      saveLoading:false,
      formData:{
        mallSlect:{},       //选择的项目
        configType:'',      //预警类型
        configValue:0,      //
        configValue1:'',        
      },
      
      mallList:[
        {name:'青岛项目',type:'招商合同到期预警',no:'多经合同到期预警',time:'2020-15-15'}
      ],
      warnList:[],            //预警类型
      warnDateTypeList:[],    //预警天数的单位:天、月、日等
    }
  },
  created(){
    if(this.$route.query.type=="add"){
      this.isAdd = true;
    }else{
      this.isAdd = false;
      this.modifyId = this.$route.query.id;
    }
    this.getMall();
  },
  methods:{
    async getMall(){
      let user = Utils.getSession("userInfo");
      let data = await Api.getMall({'userId':user.userId})
      this.mallList = data.data.data;

      data = await Api1.getWarningconfigType({dictType:"alert_type"});      //获取预警类型
      data.data.data.forEach(item=>{
        this.warnList.push(item.dictName)
      })

      data = await Api1.getWarningconfigType({dictType:"time_unit"});       //获取预警天数的单位:天、月、日等
      data.data.data.forEach(item=>{
        this.warnDateTypeList.push(item)
      })

      if(!this.isAdd){
        let params = {
          bizType:1,
          id:this.modifyId
        }
        data = await Api1.getWarningDetail(params)
        let {mallCode,configType,configValue,configValue1} = data.data.data
        for(let i=0;i<this.mallList.length;i++){
          if(this.mallList[i].mallCode==mallCode){
            this.formData.mallSlect = this.mallList[i];
            break;
          }
        }
        this.formData.configType = configType
        this.formData.configValue = configValue
        this.formData.configValue1 = configValue1        
      }
    },
    back(){
      this.$router.back();
    },
    save(){
      // this.saveLoading=true;
      if(this.formData.mallSlect.mallCode==''){
        this.$message.error('请选择项目');
        return;
      }
      if(this.formData.configType==''){
        this.$message.error('请选择预警类型');
        return;
      }
      if(this.formData.configValue==''){
        this.$message.error('请输入预警天数');
        return;
      }
      if(this.formData.configValue1==''){
        this.$message.error('请选择预警天数的类型');
        return;
      }
      let params={
        bizType:1,
        mallCode:this.formData.mallSlect.mallCode,
        mallName:this.formData.mallSlect.mallName,
        configType:this.formData.configType,
        configValue:this.formData.configValue,
        configValue1:this.formData.configValue1,
      }
      if(!this.isAdd){
        params.id = this.modifyId
      }
      Api1.addWarningList(params).then(res=>{
        if(res.data.code==200){
          this.$message.success(this.isAdd?'新增预警成功':'编辑预警成功')          
          this.$router.push('/earlywarning');
        }else{
          this.$message.error(res.data.message);
        }
      })
      
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .configType{
  .rs-form-item__content{
    margin-left: 40px !important;
  }  
}
</style>