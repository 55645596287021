var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("header-title", {
        attrs: { title: _vm.isAdd ? "新增预警" : "编辑预警" },
      }),
      _c(
        "rs-form",
        {
          ref: "formData",
          staticClass: "form-100 commom-form",
          attrs: {
            model: _vm.formData,
            "show-message": false,
            "label-width": "156px",
            size: "small",
          },
        },
        [
          _c(
            "rs-row",
            [
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "rs-form-item",
                    { attrs: { label: "项目名称" } },
                    [
                      _c(
                        "rs-select",
                        {
                          attrs: {
                            "popper-append-to-body": false,
                            "popper-class": "dialog-popper",
                            "value-key": "mallCode",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.formData.mallSlect,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "mallSlect", $$v)
                            },
                            expression: "formData.mallSlect",
                          },
                        },
                        _vm._l(_vm.mallList, function (item) {
                          return _c("rs-option", {
                            key: item.mallCode,
                            attrs: { label: item.mallName, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "rs-row",
            [
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "rs-form-item",
                    { attrs: { label: "预警类型" } },
                    [
                      _c(
                        "rs-select",
                        {
                          attrs: {
                            "popper-append-to-body": false,
                            "popper-class": "dialog-popper",
                            "value-key": "id",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.formData.configType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "configType", $$v)
                            },
                            expression: "formData.configType",
                          },
                        },
                        _vm._l(_vm.warnList, function (item) {
                          return _c("rs-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "rs-row",
            [
              _c(
                "rs-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "rs-form-item",
                    { attrs: { label: "预警天数" } },
                    [
                      _c("rs-input", {
                        attrs: {
                          maxlength: "8",
                          "popper-class": "dialog-popper",
                          "value-key": "id",
                        },
                        model: {
                          value: _vm.formData.configValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "configValue", _vm._n($$v))
                          },
                          expression: "formData.configValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "rs-col",
                { staticClass: "configType", attrs: { span: 6 } },
                [
                  _c(
                    "rs-form-item",
                    [
                      _c(
                        "rs-select",
                        {
                          attrs: {
                            "popper-append-to-body": false,
                            "popper-class": "dialog-popper",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.formData.configValue1,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "configValue1", $$v)
                            },
                            expression: "formData.configValue1",
                          },
                        },
                        _vm._l(_vm.warnDateTypeList, function (item, index) {
                          return _c("rs-option", {
                            key: index,
                            attrs: {
                              label: item.dictName,
                              value: item.dictValue,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "edit-form button-box",
          staticStyle: { "text-align": "right" },
        },
        [
          _c(
            "rs-button",
            { attrs: { size: "small" }, on: { click: _vm.back } },
            [_vm._v("取消")]
          ),
          _c(
            "rs-button",
            {
              attrs: {
                size: "small",
                type: "primary",
                loading: _vm.saveLoading,
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }